exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-approach-index-js": () => import("./../../../src/pages/approach/index.js" /* webpackChunkName: "component---src-pages-approach-index-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ourwork-ciscasestudy-js": () => import("./../../../src/pages/ourwork/ciscasestudy.js" /* webpackChunkName: "component---src-pages-ourwork-ciscasestudy-js" */),
  "component---src-pages-ourwork-index-js": () => import("./../../../src/pages/ourwork/index.js" /* webpackChunkName: "component---src-pages-ourwork-index-js" */),
  "component---src-pages-pricing-index-js": () => import("./../../../src/pages/pricing/index.js" /* webpackChunkName: "component---src-pages-pricing-index-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-thankyou-index-js": () => import("./../../../src/pages/thankyou/index.js" /* webpackChunkName: "component---src-pages-thankyou-index-js" */)
}

